<template>
  <div>
    <div v-if="preloader" class="loader-center">
      <div class="atom-spinner">
        <div class="spinner-inner">
          <div class="spinner-line"></div>
          <div class="spinner-line"></div>
          <div class="spinner-line"></div>
          <div class="spinner-circle">
            <img src="../assets/img/logo-01.png" class="logostyle" alt="..." />
          </div>
        </div>
      </div>
    </div>
    <div class="outer" v-else>
      <br />
      <v-row class="outsideRow">
        <v-col cols="12">
          <v-card tile>
            <v-row>
              <v-col cols="12" md="6">
                <div class="d-flex child-flex">
                  <img
                    :src="item.banner_assets"
                    :lazy-src="item.banner_assets"
                    class="imgResize"
                  />
                  <div class="packageHeading">
                    <h3 class="exploretitle">
                      {{ item.package_title }}
                    </h3>

                    <br />
                    <p class="exploreclr exploremobile">
                      {{ item.destination }}
                    </p>
                    <br />
                    <div class="exploreclr d-flex">
                      <div v-if="item.facilities.includes('Gym') != ''">
                        <v-icon color="yellow darken-1"
                          >mdi-weight-lifter</v-icon
                        >
                        <p class="packageOption">Gym</p>
                      </div>
                      <div
                        class="packageContainer"
                        v-if="item.bed_details[0].single_bed == true"
                      >
                        <v-icon color="yellow darken-1">mdi-bed</v-icon>
                        <p class="packageOption">Single</p>
                      </div>

                      <div
                        class="packageContainer"
                        v-if="item.bed_details[0].double_bed == true"
                      >
                        <v-icon color="yellow darken-1">mdi-bed</v-icon>
                        <p class="packageOption">Double</p>
                      </div>
                      <div
                        class="packageContainer"
                        v-if="item.bed_details[0].triple_bed == true"
                      >
                        <v-icon color="yellow darken-1">mdi-bed</v-icon>
                        <p class="packageOption">Triple</p>
                      </div>
                      <div
                        class="packageContainer"
                        v-if="item.bed_details[0].dormitory == true"
                      >
                        <v-icon color="yellow darken-1">mdi-bed</v-icon>
                        <p class="packageOption">Dormitory</p>
                      </div>
                      <div
                        class="packageContainer"
                        v-if="item.facilities.includes('Free Parking') != ''"
                      >
                        <v-icon color="yellow darken-1">
                          mdi-car-multiple
                        </v-icon>
                        <p class="packageOption">Parking</p>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="d-flex child-flex">
                  <div>
                    <h3 class="exploretitle">Check-In</h3>
                    <div class="d-flex" style="margin-top: 8px">
                      <v-icon>event</v-icon>
                      <p class="PackageDate">
                        <span class="packageD">{{ convertDate(checkin) }}</span>
                      </p>
                    </div>
                    <div class="d-flex">
                      <v-icon> mdi-clock-outline </v-icon>
                      <p class="PackageDate">
                        <span class="packageD">4</span>&nbsp;PM
                      </p>
                    </div>
                  </div>
                  <div>
                    <h3 class="exploretitle">Check-Out</h3>
                    <div class="d-flex" style="margin-top: 8px">
                      <v-icon>event</v-icon>
                      <p class="PackageDate">
                        <span class="packageD">{{
                          convertDate(checkout)
                        }}</span>
                      </p>
                    </div>
                    <div class="d-flex">
                      <v-icon> mdi-clock-outline </v-icon>
                      <p class="PackageDate">
                        <span class="packageD">12</span>&nbsp;PM
                      </p>
                    </div>
                  </div>
                </div>
                <br />
                <v-divider style="max-width: 100%"></v-divider>
                <div class="d-flex">
                  <p style="color: grey darken-1; padding: 5px">
                    Nights: <b> {{ this.pricing.daysCount }} </b>
                  </p>
                  &nbsp;
                  <!-- <p style="color: grey darken-1; padding: 5px">
                    Room(s): <b> 1 </b>
                  </p> -->
                </div>
              </v-col>
            </v-row>
            <br />
            <v-divider style="max-width: 100%"></v-divider>
            <p style="color: grey darken-1; padding: 5px">
              <b> Price Details </b>US${{ price }}
            </p>
          </v-card>
        </v-col>

        <v-col cols="12" md="9">
          <v-card>
            <v-simple-table fixed-header height="160px" class="tablestyle">
              <thead>
                <tr>
                  <!-- <th
                    class="text-left"
                    style="
                      font-size: 20px;
                      font-weight: 500px;
                      color: black;
                      background-color: white;
                    "
                  >
                    Room
                  </th> -->
                  <br />
                  <th
                    class="text-left"
                    style="
                      font-size: 20px;
                      font-weight: 500px;
                      color: black;
                      background-color: white;
                    "
                  >
                    Guests
                  </th>
                  <th
                    class="text-left"
                    style="
                      font-size: 20px;
                      font-weight: 500px;
                      color: black;
                      background-color: white;
                    "
                  >
                    Price/nights
                  </th>
                  <th
                    class="text-left"
                    style="
                      font-size: 16px;
                      font-weight: 500px;
                      color: black;
                      background-color: white;
                    "
                  >
                    No. Of Nights
                  </th>
                  <th
                    class="text-left"
                    style="
                      font-size: 20px;
                      font-weight: 500px;
                      color: black;
                      background-color: white;
                    "
                  >
                    Total Price
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <!-- <td>1</td> -->
                  <br />
                  <!-- <td>Breakfast</td> -->
                  <td>
                    Adults:{{ adult }} | Children:{{ children }} | Infant:{{
                      infant
                    }}
                  </td>
                  <td>{{ this.pricing.packagePrice }}</td>
                  <td>{{ this.pricing.daysCount }}</td>
                  <td>{{ this.pricing.total }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card class="mx-auto" max-width="500" style="padding: 10px">
            <div ref="paypal" id="paypalContainer"></div>
            <v-divider style="margin-bottom: 15px"></v-divider>
            <h5 style="text-align: center; margin-bottom: 5px">
              Also Pay With
            </h5>
            <!-- <form action="https://test.bitpay.com/checkout" method="post">
              <input type="hidden" name="action" value="checkout" />
              <input type="hidden" name="posData" value="" />
              <input type="hidden" name="notificationType" value="json" />
              <input
                type="hidden"
                name="data"
                value="Xi6cwnZldpZIBaXgk3yllTslpQAT1gbsiujTicdU8bEDmK2wDaaCjjhThzij3F4odkU2xgSdcHHTPDlyzvqm3GzCyH1NbPpSeal+3YSVzhhcB66qz2QEttS2S0PgOjknNKCsSUIJOKLxw8K6i+a3yA=="
              />
              <input
                type="image"
                src="https://test.bitpay.com/cdn/en_US/bp-btn-pay-currencies.svg"
                name="submit"
                style="width: 350px"
                alt="BitPay, the easy way to pay with bitcoins."
              />
            </form> -->
            <v-btn @click="getBitpayPaymentId" color="primary" block
              >Bitpay</v-btn
            >
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import msgHelper from '../../utils/msg-helper';
import EventBus from '../../utils/EventBus';

export default {
  name: 'Paypal',
  data() {
    return {
      preloader: false,
      pricepernights: 0,
      packageprice: '',

      adults: 0,
      children: 0,
      infant: 0,
      price: '',
      nights: '',
      checkin: '',
      checkout: '',
      item: '',
      package_admin_id: '',
      modified_package_id: '',
      id: '',
      username: '',
      pid: '',
      mid: '',
      headers: [
        { text: 'Room', align: 'start', value: 'Room' },
        { text: 'Inclutions', value: 'Inclutions' },
        { text: 'Guests', value: 'Guests' },
        { text: 'Price/Night', value: 'Price' },
        { text: 'No. of Night', value: 'No_Night' },
        { text: 'Total Price', value: 'Total_Price', align: 'end' },
      ],
      bitpayProcessStarted: false,
      bitpayRunning: false,
      bitpayProcessFailed: false,
      paymentStatus: '',
      isPaymentCompleted: false,
      finalStartDate: null,
      finalEndDate: null,
      guestCount: null,
      updatedPrice: null,
    };
  },
  computed: {
    ...mapState(['pricing', 'paypal_id', 'packageDetails', 'user']),
  },
  mounted() {
    if (this.user === null) {
      EventBus.$emit('openSignInOrUpDialog', false);
      msgHelper.showToast(
        'warning',
        'Please login before proceeding to pay for the package'
      );
    }
    document.title = `TuroBuddy | Make Payment`;
    const script = document.createElement('script');
    script.src = 'https://www.paypal.com/sdk/js?client-id=' + this.paypal_id;
    script.addEventListener('load', this.paymentUsingPaypal);
    document.body.appendChild(script);
  },
  created() {
    const currentPath = this.$router.currentRoute;
    const isMId = currentPath.query?.id;
    this.item = this.packageDetails;
    this.package_admin_id = localStorage.getItem('package_admin_id');
    this.modified_package_id = localStorage.getItem('modified_package_id');

    // CHECK IN & OUT
    this.checkin = localStorage.getItem('startDate');
    this.checkout = localStorage.getItem('endDate');
    // TOTAL DAY COUNT
    this.nights = localStorage.getItem('days');
    this.price = localStorage.getItem('totalprice');

    // BOOKING FOR DETAILS
    this.adult = localStorage.getItem('adult');
    this.children = localStorage.getItem('children');
    this.infant = localStorage.getItem('infrant');

    this.packageprice = localStorage.getItem('price');
    this.pricepernights = localStorage.getItem('pricepernights');

    if (isMId) {
      const ids = window.atob(isMId).split(':');
      this.pid = ids[0];
      this.mid = ids[1];
      this.fetchModificationPackage(this.mid);
      this.getPackageById(this.pid);
    }
  },

  methods: {
    async getPackageById(id) {
      try {
        const res = await this.$http.post('fetchParticularPackage', {
          package_id: id,
        });
        this.$store.commit('setPackageDetails', res.data.data);
        this.$store.commit('setPackageId', id);
      } catch (e) {
        console.log(e);
        msgHelper.showToast(
          'error',
          'Something went wrong getting the package details'
        );
      }
    },
    async fetchModificationPackage(id) {
      try {
        const res = await this.$http.post(
          'fetchParticularModificationPackage',
          {
            modified_package_id: id,
          }
        );
        this.finalStartDate = res.data.data.start_date;
        this.finalEndDate = res.data.data.end_date;
        this.guestCount = res.data.data.guest;
        this.updatedPrice = res.data.data.modification_price;
        const pricing = { ...this.pricing };
        const dates = [];
        let start = this.$moment.unix(this.finalStartDate);
        let end = this.$moment.unix(this.finalEndDate);
        pricing.noOfCustomers =
          res.data.data.guest_details[0].adult +
          res.data.data.guest_details[0].children +
          res.data.data.guest_details[0].infant;
        pricing.packagePrice = res.data.data.total_price;
        pricing.total = this.updatedPrice;
        console.log('final check package booking details', pricing);
        while (start < end) {
          dates.push(start.format('DD-MM-YYYY'));
          start = this.$moment(start).add(1, 'day');
        }
        pricing.daysCount = dates.length;
        this.$store.commit('setPricing', pricing);
      } catch (e) {
        console.log(e);
        await msgHelper.showToast(
          'error',
          'Failed to get the package modification details, please try again later'
        );
      }
    },
    async getBitpayPaymentId() {
      console.log(this.user);
      if (this.user === null) {
        EventBus.$emit('openSignInOrUpDialog', false);
        return;
      }
      this.bitpayProcessStarted = true;
      this.bitpayRunning = false;
      this.bitpayProcessFailed = false;

      try {
        const jsonObj = {
          user_id: this.user._id,
          package_id: this.pid,
          total_package_price: this.pricing.total,
          start_date: Number(this.checkin),
          end_date: Number(this.checkout),
          package_admin_id: this.package_admin_id,
          modified_package_id: this.mid ? this.mid : '',
          booking_for: {
            adult: Number(this.adult),
            child: this.child ? Number(this.child) : 0,
            infant: Number(this.infant),
          },
        };
        const bitpayPaymentDetails = (
          await this.$http.post('paidViaBitpay', jsonObj)
        ).data;
        window.bitpay.enableTestMode();
        window.bitpay.showInvoice(bitpayPaymentDetails.payment_id);
        this.paymentStatus = null;
        window.addEventListener(
          'message',
          function (event) {
            this.paymentStatus = event.data.status;
            if (this.paymentStatus === 'paid') {
              this.isPaymentCompleted = true;
              return;
            }
          },
          false
        );
        window.bitpay.onModalWillLeave(async () => {
          console.log('modal is closing');
          if (this.isPaymentCompleted) {
            await msgHelper.showToast('success', 'Payment Successful');
          } else {
            console.log('resulting cancelled or failed');
          }
        });
        this.bitpayRunning = true;
        this.bitpayProcessFailed = false;
      } catch (e) {
        this.bitpayProcessFailed = true;
        this.bitpayProcessStarted = false;
        this.bitpayRunning = false;
      }
    },
    convertDate(unixDate) {
      return moment.unix(unixDate).format('Do MMMM,YYYY');
    },
    paymentUsingPaypal() {
      this.loader = true;
      window.paypal
        .Buttons({
          createOrder: async (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: 'Attempt n.1 for Quote ID',
                  amount: {
                    currency_code: 'USD',
                    value: this.pricing.total,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            await this.$http.post('paidViaPaypal', {
              payment_id: order.id,
              user_id: this.user._id,
              package_admin_id: this.package_admin_id,
              modified_package_id: this.mid ? this.mid : '',
              package_id: this.pid,
              total_package_price: this.pricing.total,
              start_date: Number(this.checkin),
              end_date: Number(this.checkout),
              booking_for: {
                adult: Number(this.adult),
                child: this.child ? Number(this.child) : 0,
                infant: Number(this.infant),
              },
              paid_on: this.$moment().unix(),
              provider_response: [order],
              is_expire: false,
              is_paid: true,
            });
            setTimeout(() => {
              this.loader = false;
              msgHelper.showToast('success', 'Transaction Successful!');
            }, 1000);
          },
          onError: (err) => {
            console.log(err);
            this.loader = false;
            msgHelper.showToast(
              'error',
              'Transaction not completed successfully! Please try again later'
            );
          },
        })
        .render(this.$refs.paypal);
    },
  },
};
</script>

<style scoped src="../assets/css/style.css">
.card-div {
  height: 40px;
}
v-row {
  padding: 0px !important;
}
</style>
